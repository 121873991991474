<template>
    <!--<div>
        <a-layout>
            <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
                <a-layout-content style="padding:0;">-->
                    <div class="yj-conten " style="background: #fff;padding: 14px 20px ;margin: 0;">
                        <a-row class="yj-form">
                            <a-col :span="24">
                                <label class="yj-form_label">姓名:</label>
                                <a-input id="name"
                                         contextmenu="2455"
                                         :value="name"
                                         style="width:260px"
                                         @change="name_change"
                                         placeholder="姓名"></a-input>
                                <a-button type="primary" @click="search">查询</a-button>
                            </a-col>
                        </a-row>
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 style=" min-height: calc(100vh - 234px);min-width: max-content"
                                 class="yj-table-img">
                            <span slot="imgs" slot-scope="text">
                                <img class="imgPreview" :src="text" alt="" />
                            </span>
                            <span slot="ReadCount" slot-scope="text, record">
                                <a @click="ReadCount(record)" style="cursor:pointer;">{{record.ReadCount }}</a>
                            </span>
                            <span slot="NewUserCount" slot-scope="text, record">
                                <a @click="NewUserCount(record)" style="cursor:pointer;">{{record.NewUserCount }}</a>
                            </span>
                        </a-table>
                    </div>
                <!--</a-layout-content>
            </div>
        </a-layout>
    </div>-->
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Store_PartnerList",
        data() {
            return {
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                tableData: [],
                ID: "",
                columns: [
                    {
                        title: "头像",
                        dataIndex: "UHeadImg",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                    },
                    {
                        title: "姓名",
                        dataIndex: "Name",
                    },
                    {
                        title: "电话",
                        dataIndex: "Phone",
                        customRender: (text, item) => {
                            var Phone = "";
                            if (item.Phone.length == 11) {
                                Phone = item.Phone;
                            }
                            return Phone;
                        }
                    },
                    {
                        title: "阅读量",
                        scopedSlots: { customRender: 'ReadCount' }
                    },
                    {
                        title: "拉新数量",
                        scopedSlots: { customRender: 'NewUserCount' }
                    },
                    {
                        title: "最新时间",
                        width: 230,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.NewTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "领取时间",
                        width: 230,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    }
                ],
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialPartnerMVCList",
                    data: {
                        ID: self.ID,
                        pageIndex: self.pagination.current,
                        name: self.name
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            ReadCount: function (data) {
                var self = this;
                self.$router.push({
                    name: "Material_ReadList", query: {
                        ID: self.ID,
                        IsNewUser: 0,
                        IntroducerID: data.IntroducerID,
                    }
                })
            },
            NewUserCount: function (data) {
                var self = this;
                self.$router.push({
                    name: "Material_ReadList", query: {
                        ID: self.ID,
                        IsNewUser: 1,
                        IntroducerID: data.IntroducerID,
                    }
                })
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.ID = this.$route.query.ID;
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>